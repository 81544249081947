import Page from "@jumbo/shared/Page";
import AddGuest from "app/pages/Guest/AddGuest";
import EditGuest from "app/pages/Guest/EditGuest";

import GuestList from "app/pages/Guest/GuestList";

const routesName = "/guest";

var routes = [
  {
    path: `${routesName}`,
    element: <Page component={GuestList} layout={"vertical-default"} />,
    // permission: "view",
  },
  {
    path: `${routesName}/add`,
    element: <Page component={AddGuest} layout={"vertical-default"} />,
  },
  {
    path: `${routesName}/edit/:id`,
    element: <Page component={EditGuest} layout={"vertical-default"} />,
    // permission: "edit",
  },
];

export const guestRoute = routes;
