import Div from "@jumbo/shared/Div/Div";
import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import CustomTable from "app/components/Table";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import { useSelector } from "react-redux";
import { onGuestList } from "app/redux/actions/Guest";
import ToastAlerts from "app/components/Toast";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { getCustomDateTime } from "@jumbo/utils";
import { Axios } from "app/services/config";
import PrintIcon from "@mui/icons-material/Print";
import Swal from "sweetalert2";
import LockResetOutlinedIcon from "@mui/icons-material/LockResetOutlined";
import ViewGuest from "../ViewGuest";
import FullScreenLoader from "app/components/ListingPageLoader";
import SearchGlobal from "app/shared/SearchGlobal";
import Documents1 from "app/components/Documents1";
import moment from "moment";
export default function GuestList() {
  const navigate = useNavigate();
  const showAlert = ToastAlerts();
  const dispatch = useDispatch();
  const statusList = ["Attended", "Not Attended"];
  const { role_id } = JSON.parse(localStorage.getItem("authUser")) || {};
  const [query, setQuery] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [openView, setOpenView] = useState(false);
  const [count, setCount] = useState({});
  const [guestDetails, setGuestDetails] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { guestList, totalPages, error, loading } = useSelector(
    (state) => state.guestReducer
  );

  const columns = [
    {
      field: "guest_id",
      headerName: "Guest ID",
      sortable: true,

    },
    {
      field: "first_name",
      headerName: "First Name",
      sortable: true,
    },
    { field: "last_name", headerName: "Last Name", sortable: true },
    { field: "category", headerName: "Category", sortable: true },
    { field: "email_id", headerName: "Email ID", sortable: true },
    // {
    //   field: "registration_date",
    //   headerName: "Reg. Date",
    //   sortable: true,
    //   render: (_, elm) =>
    //     getCustomDateTime(elm?.registration_date, "days", "DD MMM YYYY"),
    // },
    // { field: "gender", headerName: "Gender", sortable: true },

    {
      field: "company_name",
      headerName: "Company",
      sortable: true,
      width: 200,
    },

    {
      field: "attendance_status",
      headerName: "Attendance Status",
      sortable: true,
    },
    {
      field: "in_time",
      headerName: "IN TIME",
      sortable: false,
      width: 80,
      render: (_, elm) =>
        elm?.in_time ? moment(elm?.in_time).format("h:mm:ss a") : "-",
    },
    {
      field: "status",
      headerName: "Status",
      sortable: true,
      render: (value, elm) =>
        value ? (
          <Button size="small" variant="outlined" color="success">
            Active
          </Button>
        ) : (
          <Button size="small" variant="outlined" color="error">
            Inactive
          </Button>
        ),
      onClick: async (elm) => {
        try {
          let status = elm.status;
          const result = await Swal.fire({
            title: `Change guest status to ${status ? "inactive" : "active"} ?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
          });
          if (result.isConfirmed) {
            await Axios.patch(`/guest/edit/${elm._id}`, { status: !status });
            showAlert("success", "Guest status updated successfully.");
            navigate("/guest");
            dispatch(onGuestList(query));
          }
        } catch (error) {
          console.error("Error updating guest:", error);
          showAlert("error", "Failed to update guest.");
        }
      },
    },
  ];

  const PrintQrCode = async (row, setLoader) => {
    setLoader(true);
    try {
      const qrCodeDataUrl = await Axios.patch(
        `/guest/qr-code-pass?id=${row?._id}`
      );
      dispatch(onGuestList(query));
      GetAttendedCount();
      setLoader(false);
      return qrCodeDataUrl?.data?.data;
    } catch (error) {
      setLoader(false);
      showAlert("error", "Error generating QR code.");
    }
  };

  const GetAttendedCount = async () => {
    try {
      const res = await Axios.get(`/guest/get-attended-count`);
      setCount(res?.data?.data);
    } catch (error) {
      showAlert("error", "Error generating QR code.");
    }
  };

  const GuestExportCsv = async () => {
    setIsLoading(true);
    try {
      const filterValue = selectedStatus || "";
      let apiUrl = `/report/guestListCsv?attendance_status=${filterValue}`;
      const response = await Axios.get(apiUrl);
      console.log(response, "response");
      if (response?.data?.status === true) {
        showAlert("success", "Downloaded successfully.");
        window.open(response?.data?.data);
      }
    } catch (error) {
      showAlert("error", error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const actions = [
    {
      label: "View Details",
      color: "secondary",
      icon: <PreviewOutlinedIcon />,
      onClick: (row) => {
        setGuestDetails(row);
        setOpenView(true);
      },
    },
    {
      label: "Edit",
      color: "secondary",
      onClick: (row) => navigate(`/guest/edit/${row._id}`, { state: row }),
      icon: <ModeEditOutlinedIcon />,
    },
    {
      label: "Print Pass",
      color: "secondary",
      onClick: async (row) => {
        const link = await PrintQrCode(row, setLoader);
        if (link) {
          window.open(link);
        }
      },
      icon: <PrintIcon />,
    },
    ...(role_id?.permissions?.guest?.edit
      ? [
          {
            label: "Edit",
            color: "secondary",
            onClick: (row) =>
              navigate(`/guest/edit/${row._id}`, { state: row }),
            icon: <ModeEditOutlinedIcon />,
          },
          {
            label: "Change Password",
            color: "primary",
            onClick: (row) => navigate(`/guest/change-password/${row._id}`),
            icon: <LockResetOutlinedIcon />,
          },
        ]
      : []),
  ];

  const fetchData = (props) => {
    setQuery({ ...query, ...props });
  };

  useEffect(() => {
    setQuery({ ...query, search: searchTerm });
  }, [searchTerm]);

  const handleFilter = () => {
    setQuery({ ...query, attendance_status: selectedStatus });
  };

  const handleClearFilter = () => {
    setSelectedStatus(null);
    setQuery({ ...query, attendance_status: "" });
  };

  if (error) {
    showAlert("error", error);
  }

  useEffect(() => {
    GetAttendedCount();
    dispatch(onGuestList(query));
  }, [query]);

  return (
    <>
      {(loading || loader || isLoading) && <FullScreenLoader />}
      <Div
        sx={{
          mt: -4,
          maxHeight: "89vh",
          overflowY: "scroll",
          paddingRight: "10px",
        }}
      >
        <Div
          sx={{
            position: "sticky",
            top: 0,
            background: "#F5F7FA",
            zIndex: 10,
          }}
        >
          <Typography variant="h1">Guest Master</Typography>

          <Grid container spacing={3.75} mt={2}>
            <Grid item>
              <Documents1
                icone={<PeopleAltIcon sx={{ fontSize: 36 }} />}
                field="Total Register Guest"
                data={count[0]?.registered ? count[0]?.registered : 0}
              />
            </Grid>
            <Grid item>
              <Documents1
                icone={<PeopleAltIcon sx={{ fontSize: 36 }} />}
                field="Total Attended Guest"
                data={count[0]?.attended ? count[0]?.attended : 0}
              />
            </Grid>
          </Grid>

          <Div
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Div
              sx={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                gap: "35px",
                width: "80%",
              }}
            >
              <SearchGlobal
                sx={{
                  mb: 5,
                  mt: 4,
                  maxWidth: { xs: 240, md: 320 },
                }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />

              <Div
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "50%",
                }}
              >
                <Autocomplete
                  size="small"
                  fullWidth
                  id="tags-standard"
                  options={statusList}
                  getOptionLabel={(option) => option}
                  value={selectedStatus}
                  onChange={(e, newValue) => {
                    setSelectedStatus(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Attendance Status" />
                  )}
                />

                <Div sx={{ display: "flex", gap: 1, flex: "1", ml: 2 }}>
                  <Button
                    size="small"
                    variant="contained"
                    sx={{ height: "35px" }}
                    onClick={handleFilter}
                  >
                    Apply
                  </Button>

                  <Button
                    size="small"
                    variant="outlined"
                    sx={{ height: "35px" }}
                    onClick={handleClearFilter}
                  >
                    Clear
                  </Button>
                </Div>
              </Div>
            </Div>

            <Div
              sx={{
                display: "flex",
                justifyContent: "right",
                alignItems: "center",
                gap: "15px",
                width: "40%",
              }}
            >
              <Button
                size="small"
                disabled={guestList?.length === 0}
                variant="contained"
                sx={{ p: 1, pl: 4, pr: 4 }}
                onClick={async () => {
                  GuestExportCsv();
                }}
              >
                export csv
              </Button>
              <Button
                size="small"
                variant="contained"
                sx={{ p: 1, pl: 4, pr: 4 }}
                onClick={() => navigate("/guest/add")}
              >
                Add Guest
              </Button>
            </Div>
          </Div>
        </Div>

        <Div>
          <CustomTable
            data={guestList}
            columns={columns}
            actions={actions}
            fetchData={fetchData}
            totalCount={totalPages}
          />
        </Div>
        {openView && guestDetails && (
          <ViewGuest
            openView={openView}
            setOpenView={setOpenView}
            data={guestDetails}
          />
        )}
      </Div>
    </>
  );
}
