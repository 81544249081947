export const VISIBLE_DIALOG_OPTIONS = {
  NONE: "none",
  SESSION_EDIT: "contact-edit-dialog",
  SESSION_DETAIL: "contact-detail-dialog",
  SESSION_CONFIRM_DELETE: "contact-confirm-delete",
  LABEL_EDIT: "label-edit-dialog",
  LABEL_CONFIRM_DELETE: "label-confirm-delete",
};
export const SESSION_ADDED = "session-add";
export const SESSION_DELETE = "session-delete";
export const SESSION_EDIT = "session-edit";
export const SESSION_LIST = "session-list";
export const GLOBAL_SESSION_LIST = "global-session-list";
export const SESSION_ERROR = "session-error";
export const SESSION_REQUEST = "room_request";

export const SESSION_DELETE_COMPLETED = "set-on-contact-delete-complete";
export const SESSION_EDIT_COMPLETED = "set-on-contact-edit-complete";
export const SESSION_READ_STARTED = "set-on-contact-read-start";
export const SESSION_READ_COMPLETED = "set-on-contact-read-complete";
export const HIDE_VISIBLE_DIALOG = "set-visible-dialog";

export const LABEL_ADDED = "set-on-label-add";
export const LABEL_EDIT_STARTED = "set-on-label-edit-start";
export const LABEL_EDIT_COMPLETED = "set-on-label-edit-completed";
export const LABEL_DELETE_STARTED = "set-on-label-delete-start";
export const LABEL_DELETE_COMPLETED = "set-on-label-delete-complete";
export const LABEL_LIST_REFRESH_COMPLETED =
  "set-on-label-list-refresh-completed";

export const ASSIGN_LABELS_TO_SESSION = "set-on-assign-labels-to-contacts";
