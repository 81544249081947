import Div from "@jumbo/shared/Div/Div";
import React, { useEffect, useState } from "react";
import { Autocomplete, Button, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import CustomTable from "app/components/Table";
import ViewUser from "../ViewUser";
import LockResetOutlinedIcon from "@mui/icons-material/LockResetOutlined";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import { onUserList } from "app/redux/actions/User";
import ToastAlerts from "app/components/Toast";
import Swal from "sweetalert2";
import { Axios } from "app/services/config";
import FullScreenLoader from "app/components/ListingPageLoader";
import SearchGlobal from "app/shared/SearchGlobal";

export default function ListUser() {
  const rolesList = ["Admin", "Mobile"];
  const navigate = useNavigate();
  const showAlert = ToastAlerts();
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);
  const [openView, setOpenView] = useState(false);
  const [userDetails, setUserDetails] = useState(false);
  const [query, setQuery] = useState({});
  const { userList, totalPages, error, loading } = useSelector(
    (state) => state.userReducer
  );

  const columns = [
    { field: "user_id", headerName: "User ID", sortable: true },
    {
      field: "first_name",
      headerName: "Name",
      sortable: true,
      render: (_, elm) => elm.first_name + " " + elm.last_name,
    },

    {
      field: "role",
      headerName: "Role",
    },
    {
      field: "assigned_room",
      headerName: "Assigned Room",
      render: (_, elm) =>
        elm?.assigned_room?.room_no ? elm?.assigned_room?.room_no : "--",
    },

    { field: "email_id", headerName: "Email Id", sortable: true },
    {
      field: "mobile_no",
      headerName: "Mobile No",
      render: (_, elm) => (elm?.mobile_no ? elm?.mobile_no : "--"),
    },
    {
      field: "status",
      headerName: "Status",
      sortable: true,
      render: (value, elm) =>
        value ? (
          <Button size="small" variant="outlined" color="success">
            Active
          </Button>
        ) : (
          <Button size="small" variant="outlined" color="error">
            Inactive
          </Button>
        ),
      onClick: async (elm) => {
        try {
          console.log(elm, "elmelm");
          let status = elm.status;
          const result = await Swal.fire({
            title: `Change user status to ${status ? "inactive" : "active"} ?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
          });
          if (result.isConfirmed) {
            await Axios.patch(`/user/edit/${elm._id}`, { status: !status });
            showAlert("success", "User status updated successfully.");
            navigate("/user");
            dispatch(onUserList(query));
          }
        } catch (error) {
          console.error("Error updating user:", error);
          showAlert("error", "Failed to update user.");
        }
      },
    },
  ];

  const actions = [
    {
      label: "View Details",
      color: "secondary",
      onClick: (row) => {
        setUserDetails(row);
        setOpenView(true);
      },
      icon: <PreviewOutlinedIcon />,
    },
    {
      label: "Edit",
      color: "secondary",
      onClick: (row) => navigate(`/user/edit/${row._id}`, { state: row }),
      icon: <ModeEditOutlinedIcon />,
    },
    {
      label: "Change Password",
      color: "primary",
      onClick: (row) => navigate(`/user/change-password/${row._id}`),
      icon: <LockResetOutlinedIcon />,
    },
  ];
  const fetchData = (props) => {
    setQuery({ ...query, ...props });
  };

  const handleFilter = () => {
    setQuery({ ...query, role: selectedRole });
  };
  const handleClearFilter = () => {
    setSelectedRole(null);
    setQuery({ ...query, role: "" });
  };

  useEffect(() => {
    setQuery({ ...query, search: searchTerm });
  }, [searchTerm]);

  if (error) {
    showAlert("error", error);
  }

  useEffect(() => {
    dispatch(onUserList(query));
  }, [query]);

  return (
    <>
      {loading && <FullScreenLoader />}
      <Div
        sx={{
          mt: -4,
          maxHeight: "89vh",
          overflowY: "scroll",
          paddingRight: "10px",
        }}
      >
        <Div
          sx={{
            position: "sticky",
            top: 0,
            background: "#F5F7FA",
            zIndex: 10,
          }}
        >
          <Div sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
          <Typography variant="h1">User Master</Typography>
            
            
          </Div>
          <Div
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: 3,
              gap: 2,
            }}
           >
            <Div sx={{ width: "20%" }}>
            <Autocomplete
                size="small"
                id="tags-standard"
                options={rolesList}
                getOptionLabel={(option) => option}
                value={selectedRole}
                onChange={(e, newValue) => {
                  setSelectedRole(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Roles" />
                )}
              />
            </Div>
 
            <Div sx={{ display: "flex", gap: 1, flex: "1" }}>
              <Button
                size="small"
                variant="contained"
                sx={{ height: "35px" }}
                onClick={handleFilter}
              >
                Apply
              </Button>

              <Button
                size="small"
                variant="outlined"
                sx={{ height: "35px" }}
                onClick={handleClearFilter}
              >
                Clear
              </Button>
            </Div>
          </Div>
          <Div
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SearchGlobal
              sx={{
                mb: 5,
                mt: 4,
                maxWidth: { xs: 240, md: 320 },
              }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Div>
              <Button
                size="small"
                variant="contained"
                sx={{ p: 1, pl: 4, pr: 4 }}
                onClick={() => navigate("/user/add")}
              >
                Add User
              </Button>
            </Div>
          </Div>
        </Div>
        <Div>
          <CustomTable
            data={userList}
            columns={columns}
            actions={actions}
            fetchData={fetchData}
            totalCount={totalPages}
          />
        </Div>
        {openView && userDetails && (
          <ViewUser
            openView={openView}
            setOpenView={setOpenView}
            data={userDetails}
          />
        )}
      </Div>
    </>
  );
}
