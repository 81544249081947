import Page from "@jumbo/shared/Page";
import LogsList from "app/pages/Logs/LogsList";

const routesName = "/logs";

var routes = [
  {
    path: `${routesName}`,
    element: <Page component={LogsList} layout={"vertical-default"} />,
  },
];

export const logsRoute = routes;
