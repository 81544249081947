import Div from "@jumbo/shared/Div/Div";
import React, { useEffect, useState } from "react";
import { Autocomplete, Button, TextField, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import CustomTable from "app/components/Table";
import { useSelector } from "react-redux";
import { onLogsList } from "app/redux/actions/Logs";
import ToastAlerts from "app/components/Toast";
import moment from "moment";

import AllApis from "app/Apis/apis";
import { Axios } from "app/services/config";
import FullScreenLoader from "app/components/ListingPageLoader";
import SearchGlobal from "app/shared/SearchGlobal";
export default function LogsList() {
  const showAlert = ToastAlerts();
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedSession, setSelectedSession] = useState(null);
  const [roomNoList, setRoomNoList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sesstionList, setSessionList] = useState([]);
  const [query, setQuery] = useState({});

  const { logsList, totalPages, error, loading } = useSelector(
    (state) => state.logsReducer
  );

  const columns = [
    {
      field: "room.room_no",
      headerName: "Room Name",
      sortable: true,
      render: (_, elm) => elm?.room?.room_no,
    },
    {
      field: "session.session_name",
      headerName: "Session Name",
      sortable: true,
      width: 400,
      render: (_, elm) => elm?.session?.session_name,
    },
    {
      field: "guest.first_name",
      headerName: "Guest Name",
      sortable: true,

      render: (_, elm) => elm.guest?.first_name + " " + elm.guest?.last_name,
    },
    {
      field: "entry_in_time",
      headerName: "IN",
      sortable: false,
      render: (_, elm) =>
        elm?.entry_in_time
          ? moment(elm?.entry_in_time).format("h:mm:ss a")
          : "-",
    },
    // {
    //   field: "entry_out_time",
    //   headerName: "OUT",
    //   sortable: false,
    //   render: (_, elm) =>
    //     elm?.entry_out_time
    //       ? moment(elm?.entry_out_time).format("h:mm:ss a")
    //       : "-",
    // },
    // {
    //   field: "duration",
    //   headerName: "Duration",
    //   sortable: false,
    //   render: (_, elm) =>
    //     elm?.entry_in_time && elm?.entry_out_time
    //       ? moment
    //           .utc(moment(elm?.entry_out_time).diff(moment(elm?.entry_in_time)))
    //           .format("H:mm:ss")
    //       : "-",
    // },
  ];

  const fetchData = (props) => {
    setQuery({ ...query, ...props });
  };

  useEffect(() => {
    setQuery({ ...query, search: searchTerm });
  }, [searchTerm]);

  if (error) {
    showAlert("error", error);
  }

  const handleFilter = () => {
    setQuery({ ...query, room_no: selectedRole, session: selectedSession });
  };
  const handleClearFilter = () => {
    setSelectedRole(null);
    setSelectedSession(null);
    setQuery({ ...query, room_no: "", session: "" });
  };

  useEffect(() => {
    dispatch(onLogsList(query));
  }, [query]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await Axios.get(`${AllApis.dropdownList.room_no}`);
        setRoomNoList(response?.data?.data);
      } catch (error) {
        showAlert("error", error);
      }
    }
    fetchData();
  }, []);

  const logsExportCsv = async () => {
    setIsLoading(true);
    try {
      let apiUrl = `/report/logsListCsv`;
      if (query) {
        const queryParams = new URLSearchParams(query);
        apiUrl =
          apiUrl + (queryParams.toString() ? `?${queryParams.toString()}` : "");
      }
      const response = await Axios.get(apiUrl);
      if (response?.data?.status === true) {
        showAlert("success", "Downloaded successfully.");
        window.open(response?.data?.data);
      }
    } catch (error) {
      showAlert("error", error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    async function fetchData(id) {
      try {
        const response = await Axios.get(
          `${AllApis.dropdownList.session_name}`
        );
        setSessionList(response?.data?.data);
      } catch (error) {
        showAlert("error", error);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      {(isLoading || loading) && <FullScreenLoader />}
      <Div
        sx={{
          mt: -4,
          maxHeight: "89vh",
          overflowY: "scroll",
          paddingRight: "10px",
        }}
      >
        <Div
          sx={{
            position: "sticky",
            top: 0,
            background: "#F5F7FA",
            zIndex: 10, // Ensure the header stays above the body
          }}
        >
          <Div sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
            <Typography variant="h1">Logs Master</Typography>
          </Div>
          <Div
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: 3,
              gap: 2,
            }}
          >
            <Div sx={{ width: "20%" }}>
              <Autocomplete
                size="small"
                id="tags-standard"
                options={roomNoList}
                getOptionLabel={(option) => option}
                value={selectedRole}
                onChange={(e, newValue) => {
                  setSelectedRole(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Room Name" />
                )}
              />
            </Div>
            <Div sx={{ width: "20%" }}>
              <Autocomplete
                size="small"
                id="tags-standard"
                options={sesstionList}
                getOptionLabel={(option) => option}
                value={selectedSession}
                onChange={(e, newValue) => {
                  setSelectedSession(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Session Name" />
                )}
              />
            </Div>
            <Div sx={{ display: "flex", gap: 1, flex: "1" }}>
              <Button
                size="small"
                variant="contained"
                sx={{ height: "35px" }}
                onClick={handleFilter}
              >
                Apply
              </Button>

              <Button
                size="small"
                variant="outlined"
                sx={{ height: "35px" }}
                onClick={handleClearFilter}
              >
                Clear
              </Button>
            </Div>
          </Div>
          <Div
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SearchGlobal
              sx={{
                mb: 5,
                mt: 4,
                maxWidth: { xs: 240, md: 320 },
              }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Div>
              <Button
                size="small"
                variant="contained"
                disabled={logsList?.length === 0}
                sx={{ p: 1, pl: 4, pr: 4 }}
                onClick={async () => {
                  logsExportCsv();
                }}
              >
                export csv
              </Button>
            </Div>
          </Div>
        </Div>
        <Div>
          <CustomTable
            data={logsList}
            columns={columns}
            fetchData={fetchData}
            totalCount={totalPages}
          />
        </Div>
      </Div>
    </>
  );
}
