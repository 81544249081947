import React from "react";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
const menus = [
  {
    label: " ",
    type: "section",
    children: [
      {
        uri: "/user",
        label: "User Management",
        isActiveUri: [
          "/user/add",
          "/user/edit/:id",
          "/user/change-password/:id",
        ],
        type: "nav-item",
        icon: <PersonAddIcon sx={{ fontSize: 25 }} />,
      },
      {
        uri: "/guest",
        label: "Guests",
        isActiveUri: ["/guest/add", "/guest/edit/:id"],
        type: "nav-item",
        icon: <ContactsOutlinedIcon sx={{ fontSize: 25 }} />,
      },
      {
        uri: "/rooms",
        label: "Rooms",
        isActiveUri: ["/rooms/add", "/rooms/view/:id", "/rooms/view/:id/:id"],
        type: "nav-item",
        icon: <MeetingRoomIcon sx={{ fontSize: 25 }} />,
      },
      {
        uri: "/logs",
        label: "Logs",
        isActiveUri: ["/logs/add"],
        type: "nav-item",
        icon: <TransferWithinAStationIcon sx={{ fontSize: 25 }} />,
      },
    ],
  },
];

export default menus;
