import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import List from "@mui/material/List";
import { ListItem, ListItemText, Typography } from "@mui/material";
import { getCustomDateTime } from "@jumbo/utils";

const ViewGuest = ({ openView, setOpenView, data }) => {
  return (
    <Dialog
      open={openView}
      onClose={() => setOpenView(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <DialogTitle
        style={{ backgroundColor: "#7352C7", color: "white" }}
        id="alert-dialog-title"
      >
        Guest Details
      </DialogTitle>
      <DialogContent
        headerSx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
        sx={{ mb: 3.75 }}
      >
        <List
          disablePadding
          sx={{
            display: "flex",
            flexWrap: "wrap",
            margin: (theme) => theme.spacing(0, -2),
          }}
        >
          <ListItem
            sx={{
              width: { xs: "100%", sm: "50%", xl: "33.33%" },
              textAlign: "center",
            }}
          >
            <ListItemText
              primary={
                <Typography
                  fontSize={"12px"}
                  variant="h6"
                  color="text.secondary"
                  mb={0.5}
                >
                  Guest ID
                </Typography>
              }
              secondary={
                <Typography variant="body1" color="text.primary">
                 {data?.guest_id || "--"}
                  
                </Typography>
              }
            />
          </ListItem>
          <ListItem
            sx={{
              width: { xs: "100%", sm: "50%", xl: "33.33%" },
              textAlign: "center",
            }}
          >
            <ListItemText
              primary={
                <Typography
                  fontSize={"12px"}
                  variant="h6"
                  color="text.secondary"
                  mb={0.5}
                >
                  First Name{" "}
                </Typography>
              }
              secondary={
                <Typography variant="body1" color="text.primary">
                  {data?.first_name || "--"}
                </Typography>
              }
            />
          </ListItem>
          <ListItem
            sx={{
              width: { xs: "100%", sm: "50%", xl: "33.33%" },
              textAlign: "center",
            }}
          >
            <ListItemText
              primary={
                <Typography
                  fontSize={"12px"}
                  variant="h6"
                  color="text.secondary"
                  mb={0.5}
                >
                  Last Name
                </Typography>
              }
              secondary={
                <Typography variant="body1" color="text.primary">
                  {data?.last_name || "--"}
                </Typography>
              }
            />
          </ListItem>
          <ListItem
            sx={{
              width: { xs: "100%", sm: "50%", xl: "33.33%" },
              textAlign: "center",
            }}
          >
            <ListItemText
              primary={
                <Typography
                  fontSize={"12px"}
                  variant="h6"
                  color="text.secondary"
                  mb={0.5}
                >
                  Email ID
                </Typography>
              }
              secondary={
                <Typography variant="body1" color="text.primary">
                  {data?.email_id || "--"}
                </Typography>
              }
            />
          </ListItem>
          {/* <ListItem
            sx={{
              width: { xs: "100%", sm: "50%", xl: "33.33%" },
              textAlign: "center",
            }}
          >
            <ListItemText
              primary={
                <Typography
                  fontSize={"12px"}
                  variant="h6"
                  color="text.secondary"
                  mb={0.5}
                >
                  Gender{" "}
                </Typography>
              }
              secondary={
                <Typography variant="body1" color="text.primary">
                  {data?.gender || "--"}
                </Typography>
              }
            />
          </ListItem> */}

          <ListItem
            sx={{
              width: { xs: "100%", sm: "50%", xl: "33.33%" },
              textAlign: "center",
            }}
          >
            <ListItemText
              primary={
                <Typography
                  fontSize={"12px"}
                  variant="h6"
                  color="text.secondary"
                  mb={0.5}
                >
                  Category{" "}
                </Typography>
              }
              secondary={
                <Typography variant="body1" color="text.primary">
                  {data?.category || "--"}
                </Typography>
              }
            />
          </ListItem>

          <ListItem
            sx={{
              width: { xs: "100%", sm: "50%", xl: "33.33%" },
              textAlign: "center",
            }}
          >
            <ListItemText
              primary={
                <Typography
                  fontSize={"12px"}
                  variant="h6"
                  color="text.secondary"
                  mb={0.5}
                >
                  Company
                </Typography>
              }
              secondary={
                <Typography variant="body1" color="text.primary">
                  {data?.company_name || "--"}
                </Typography>
              }
            />
          </ListItem>
          {/* <ListItem
            sx={{
              width: { xs: "100%", sm: "50%", xl: "33.33%" },
              textAlign: "center",
            }}
          >
            <ListItemText
              primary={
                <Typography
                  fontSize={"12px"}
                  variant="h6"
                  color="text.secondary"
                  mb={0.5}
                >
                  Designation
                </Typography>
              }
              secondary={
                <Typography variant="body1" color="text.primary">
                  {data?.designation || "--"}
                </Typography>
              }
            />
          </ListItem> */}
          <ListItem
            sx={{
              width: { xs: "100%", sm: "50%", xl: "33.33%" },
              textAlign: "center",
            }}
          >
            <ListItemText
              primary={
                <Typography
                  fontSize={"12px"}
                  variant="h6"
                  color="text.secondary"
                  mb={0.5}
                >
                  In Time
                </Typography>
              }
              secondary={
                <Typography variant="body1" color="text.primary">
                  {" "}
                  {data?.in_time
                    ? getCustomDateTime(data?.in_time, "days", "HH:mm a", 0)
                    : "--"}
                </Typography>
              }
            />
          </ListItem>
          <ListItem
            sx={{
              width: { xs: "100%", sm: "50%", xl: "33.33%" },
              textAlign: "center",
            }}
          >
            <ListItemText
              primary={
                <Typography
                  fontSize={"12px"}
                  variant="h6"
                  color="text.secondary"
                  mb={0.5}
                >
                  Attendance Status
                </Typography>
              }
              secondary={
                <Typography variant="body1" color="text.primary">
                  {data?.attendance_status || "--"}
                </Typography>
              }
            />
          </ListItem>
          <ListItem
            sx={{
              width: { xs: "100%", sm: "50%", xl: "33.33%" },
              textAlign: "center",
            }}
          >
            <ListItemText
              primary={
                <Typography
                  fontSize={"12px"}
                  variant="h6"
                  color="text.secondary"
                  mb={0.5}
                >
                  Status
                </Typography>
              }
              secondary={
                <Typography variant="body1" color="text.primary">
                  {" "}
                  {data?.status ? "Active" : "Inactive"}
                </Typography>
              }
            />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          variant="outlined"
          onClick={() => setOpenView(false)}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewGuest;
