import Div from "@jumbo/shared/Div/Div";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { Button, Grid } from "@mui/material";
import Documents1 from "app/components/Documents1";
import FullScreenLoader from "app/components/ListingPageLoader";
import CustomTable from "app/components/Table";
import ToastAlerts from "app/components/Toast";
import { onRoomViewGuestList } from "app/redux/actions/Room";
import { Axios } from "app/services/config";
import SearchGlobal from "app/shared/SearchGlobal";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
// import AddSession from "../Session/AddSession";

export default function SessionGuestList() {
  const showAlert = ToastAlerts();

  const dispatch = useDispatch();
  const { state } = useLocation();
  const [searchTerm, setSearchTerm] = useState("");
  const [guestCount, setGuestCount] = useState({});
  const [openView, setOpenView] = useState(false);
  const [query, setQuery] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const { sessionViewGuestList, totalPages, error, loading } = useSelector(
    (state) => state.roomReducer
  );

  const sessionViewExportCsv = async () => {
    setIsLoading(true);
    try {
      const filterValue = selectedStatus?.session_name || "";
      const apiUrl = `/report/sessionViewListCsv?id=${state?.room_id}&sessionId=${state?._id}&filter=${filterValue}`;
      const response = await Axios.get(apiUrl);
      if (response?.data?.status === true) {
        showAlert("success", "Downloaded successfully.");
        window.open(response?.data?.data);
      }
    } catch (error) {
      showAlert("error", error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const getAllGuestCount = async (id, sessionId) => {
    try {
      const response = await Axios.get(
        `/room/get-guest-count?roomId=${id}&sessionId=${sessionId}`
      );
      setGuestCount(response.data.data);
    } catch (error) {
      console.error("Error:", error);
      showAlert("error", error.response?.data?.message || "An error occurred");
      return null;
    }
  };
  const columns = [
    {
      field: "guest.first_name",
      headerName: "First Name",
      sortable: true,
      render: (_, elm) => elm?.guest?.first_name,
    },
    {
      field: "guest.last_name",
      headerName: "Last Name",
      sortable: true,
      render: (_, elm) => elm?.guest?.last_name,
    },
    {
      field: "entry_in_time",
      headerName: "IN",
      sortable: false,
      render: (_, elm) =>
        elm?.entry_in_time
          ? moment(elm?.entry_in_time).format("h:mm:ss a")
          : "-",
    },
  ];

  const fetchData = (props) => {
    setQuery({ ...query, ...props });
  };

  useEffect(() => {
    setQuery({ ...query, search: searchTerm });
  }, [searchTerm]);

  if (error) {
    showAlert("error", error);
  }

  useEffect(() => {
    if (!openView) {
      getAllGuestCount(state?.room_id, state?._id);
      dispatch(onRoomViewGuestList(query, state?.room_id, state?._id));
    }
  }, [query, openView]);

  return (
    <>
      {(isLoading || loading) && <FullScreenLoader />}
      <Div
        sx={{
          mt: -4,
          maxHeight: "89vh",
          overflowY: "scroll",
          paddingRight: "10px",
        }}
      >
        <Div
          sx={{
            position: "sticky",
            top: 0,
            background: "#F5F7FA",
            zIndex: 10,
          }}
        >
          <Div
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10rem",
              mb: 3,
            }}
          >
            {" "}
            <Grid container spacing={3.75}>
              <Grid item maxWidth={600}>
                <Documents1
                  icone={<MeetingRoomIcon sx={{ fontSize: 36 }} />}
                  field="Session Name"
                  data={state?.session_name}
                />
              </Grid>
              <Grid item>
                <Documents1
                  icone={<PeopleAltIcon sx={{ fontSize: 36 }} />}
                  field="Total Guest"
                  data={guestCount?.guest_count ? guestCount?.guest_count : 0}
                />
              </Grid>
              {/* <Grid item>
                <Documents1
                  icone={<PeopleAltIcon sx={{ fontSize: 36 }} />}
                  field="Total Active Guest"
                  data={
                    guestCount?.active_user_count
                      ? guestCount?.active_user_count
                      : 0
                  }
                />
              </Grid> */}
            </Grid>
          </Div>
          {/* <Div
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
           >
            <Div
              sx={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                gap: "40px",
                width: "80%",
              }}
            >
              <SearchGlobal
                sx={{
                  mb: 5,
                  mt: 4,
                  maxWidth: { xs: 240, md: 320 },
                }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />

              <Div
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "40%",
                }}
               >
                <Autocomplete
                  size="small"
                  fullWidth
                  id="tags-standard"
                  options={sesstionList}
                  getOptionLabel={(option) => option?.session_name}
                  value={selectedStatus}
                  onChange={(e, newValue) => {
                    setSelectedStatus(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Session" />
                  )}
                />
                <Div sx={{ display: "flex", gap: 1, flex: "1", ml: 2 }}>
                  <Button
                    size="small"
                    variant="contained"
                    sx={{ height: "35px" }}
                    onClick={handleFilter}
                  >
                    Apply
                  </Button>

                  <Button
                    size="small"
                    variant="outlined"
                    sx={{ height: "35px" }}
                    onClick={handleClearFilter}
                  >
                    Clear
                  </Button>
                </Div>
              </Div>
            </Div>
            <Div>
              <Button
                size="small"
                disabled={roomViewList?.length === 0}
                variant="contained"
                sx={{ p: 1, pl: 4, pr: 4 }}
                onClick={async () => {
                  roomViewExportCsv();
                }}
              >
                export csv
              </Button>
            </Div>
          </Div> */}
          <Div
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SearchGlobal
              sx={{
                mb: 5,
                mt: 4,
                maxWidth: { xs: 240, md: 320 },
              }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Div
              sx={{
                display: "flex",
                justifyContent: "right",
                alignItems: "center",
                gap: "15px",
                width: "40%",
              }}
            >
              <Button
                size="small"
                disabled={sessionViewGuestList?.length === 0}
                variant="contained"
                sx={{ p: 1, pl: 4, pr: 4 }}
                onClick={async () => {
                  sessionViewExportCsv();
                }}
              >
                export csv
              </Button>
            </Div>
          </Div>
        </Div>
        <Div>
          <CustomTable
            data={sessionViewGuestList}
            columns={columns}
            fetchData={fetchData}
            totalCount={totalPages}
          />
        </Div>
      </Div>
    </>
  );
}
