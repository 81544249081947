import {
  ROOM_VIEW_LIST,
  ROOM_VIEW_REQUEST,
} from "app/redux/actions/Room/roomViewConstant";
import {
  ROOM_ADDED,
  ROOM_DELETE,
  ROOM_EDIT,
  ROOM_LIST,
  ROOM_ERROR,
  GLOBAL_ROOM_LIST,
  ROOM_REQUEST,
} from "../../actions/Room/roomConstant";
import {
  SESSION_LIST,
  SESSION_REQUEST,
} from "app/redux/actions/Room/sessionConstant";
import { SESSION_VIEW_GUEST_LIST, SESSION_VIEW_GUEST_REQUEST } from "app/redux/actions/Room/sessionViewGuestConstant";

const initialState = {
  roomList: [],
  sesstionList: [],
  roomViewList: [],
  sessionViewGuestList: [],
  globalRoomList: [],
  loading: false,
  error: null,
  successMessage: null,
  totalPages: null,
};

const reducerFunc = (state = initialState, action) => {
  switch (action.type) {
    case ROOM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ROOM_VIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ROOM_LIST:
      return {
        ...state,
        roomList: action.payload?.data || [],
        loading: false,
        error: null,
        successMessage: action.payload?.message,
        totalPages: action.payload?.totalPages,
      };
      
    case SESSION_VIEW_GUEST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SESSION_VIEW_GUEST_LIST:
      return {
        ...state,
        sessionViewGuestList: action.payload?.data || [],
        loading: false,
        error: null,
        successMessage: action.payload?.message,
        totalPages: action.payload?.totalPages,
      };
    
    case SESSION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SESSION_LIST:
      return {
        ...state,
        sesstionList: action.payload?.data || [],
        loading: false,
        error: null,
        successMessage: action.payload?.message,
        totalPages: action.payload?.totalPages,
      };

    case ROOM_VIEW_LIST:
      return {
        ...state,
        roomViewList: action.payload?.data || [],
        loading: false,
        error: null,
        successMessage: action.payload?.message,
        totalPages: action.payload?.totalPages,
      };
    case GLOBAL_ROOM_LIST:
      return {
        ...state,
        globalRoomList: action.payload?.data || [],
        loading: false,
        error: null,
        successMessage: action.payload?.message,
      };

    case ROOM_ADDED:
    case ROOM_EDIT:
    case ROOM_DELETE:
      return {
        ...state,
        loading: false,
        successMessage: action.payload?.message,
        error: null,
      };

    case ROOM_ERROR:
      return {
        ...state,
        loading: false,
        successMessage: null,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default reducerFunc;
