export const VISIBLE_DIALOG_OPTIONS = {
    NONE: "none",
    LOGS_EDIT: "contact-edit-dialog",
    LOGS_DETAIL: "contact-detail-dialog",
    LOGS_CONFIRM_DELETE: "contact-confirm-delete",
    LABEL_EDIT: "label-edit-dialog",
    LABEL_CONFIRM_DELETE: "label-confirm-delete",
};
export const LOGS_ADDED = "logs-add";
export const LOGS_DELETE = "logs-delete";
export const LOGS_EDIT = "logs-edit";
export const LOGS_LIST = "logs-list";
export const GLOBAL_LOGS_LIST = "global-logs-list";
export const LOGS_ERROR = "logs-error";
export const LOGS_REQUEST = "logs-request";

export const LOGS_DELETE_COMPLETED = "set-on-contact-delete-complete";
export const LOGS_EDIT_COMPLETED = "set-on-contact-edit-complete";
export const LOGS_READ_STARTED = "set-on-contact-read-start";
export const LOGS_READ_COMPLETED = "set-on-contact-read-complete";
export const HIDE_VISIBLE_DIALOG = "set-visible-dialog";

export const LABEL_ADDED = "set-on-label-add";
export const LABEL_EDIT_STARTED = "set-on-label-edit-start";
export const LABEL_EDIT_COMPLETED = "set-on-label-edit-completed";
export const LABEL_DELETE_STARTED = "set-on-label-delete-start";
export const LABEL_DELETE_COMPLETED = "set-on-label-delete-complete";
export const LABEL_LIST_REFRESH_COMPLETED = "set-on-label-list-refresh-completed";

export const ASSIGN_LABELS_TO_LOGSS = "set-on-assign-labels-to-contacts";
