const AllApis = {
  dropdownList: {
    room_no: "/room/dropdown-room-no",
    session_name: "/session/list-session-name",
  },
  session: {
    listsessionBasedonRoomId: function (id) {
      return `/session/list-session?id=${id}`;
    },
  
  },
};
export default AllApis;
