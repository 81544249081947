import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { LoadingButton } from "@mui/lab";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import Div from "@jumbo/shared/Div";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import CustomTable from "app/components/Table";
import ToastAlerts from "app/components/Toast";
import { onSessionList } from "app/redux/actions/Room";
import { Axios } from "app/services/config";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import * as yup from "yup";
import EditSession from "../EditSession";

const AddSession = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const showAlert = ToastAlerts();
  const dispatch = useDispatch();
  const [sessionId, setSessionId] = useState(null);
  const [openSessionView, setOpenSessionView] = useState(false);
  const [query, setQuery] = useState({});
  const { sesstionList } = useSelector((state) => state.roomReducer);

  const columns = [
    { field: "session_name", headerName: "Session Name" },
    { field: "session_description", headerName: "Session Description" },
  ];

  var initialValues = {
    session_name: "",
    session_description: "",
  };

  const validationSchema = yup.object({
    session_name: yup
      .string("Enter Session Name")
      .required("Session Name is required"),
    session_description: yup
      .string("Enter Session Description")
      .required("Session Description is required"),
  });

  const actions = [
    {
      label: "Edit Session",
      color: "secondary",
      onClick: (row) => {
        setSessionId(row?._id);
        setOpenSessionView(true);
      },
      icon: <ModeEditOutlinedIcon />,
    },
  ];

  const handleSessionAdd = async (data, setSubmitting, resetForm) => {
    try {
      setSubmitting(true);
      const sessionData = {
        ...data,
        room_id: id,
      };
      await Axios.post("/session/add-session", sessionData);
      resetForm();
      showAlert("success", "Session added successfully.");
      dispatch(onSessionList(query, id));
    } catch (error) {
      showAlert("error", error.response.data.message);
    } finally {
      setSubmitting(false);
    }
  };

  const fetchData = (props) => {
    setQuery({ ...query, ...props });
  };

  useEffect(() => {
    if (!openSessionView) {
      dispatch(onSessionList(query, id));
    }
  }, [id]);

  return (
    <React.Fragment>
      <Typography variant="h1" mb={3}>
        MANAGE SESSION ({state?.room_no})
      </Typography>
      <Card>
        <CardContent>
          <Formik
            validateOnChange={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting, resetForm }) => {
              validationSchema
                .validate(data, { abortEarly: false })
                .then(() => {
                  handleSessionAdd(data, setSubmitting, resetForm);
                })
                .catch((validationErrors) => {
                  console.error("Validation Errors:", validationErrors);
                });
            }}
          >
            {({
              setFieldValue,
              isSubmitting,
              values,
              errors,
              touched,
              handleReset,
            }) => (
              <Form noValidate autoComplete="off">
                <Grid container rowSpacing={3} columnSpacing={3}>
                  <Grid item xs={5}>
                    <JumboTextField
                      fullWidth
                      required
                      id="session_name"
                      name="session_name"
                      label="Session Name"
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <JumboTextField
                      fullWidth
                      required
                      id="session_description"
                      name="session_description"
                      label="Session Description"
                    />
                  </Grid>

                  <Grid item xs={0.7}>
                    <LoadingButton
                      variant="contained"
                      size="medium"
                      type="submit"
                      loading={isSubmitting}
                      disabled={
                        !(values.session_name && values.session_description)
                      }
                    >
                      SAVE
                    </LoadingButton>
                  </Grid>
                  <Grid item xs={0.5}>
                    <Button
                      size="medium"
                      variant="outlined"
                      onClick={handleReset}
                    >
                      Clear
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
      <Div sx={{ mt: 10 }}>
        <CustomTable
          data={sesstionList}
          columns={columns}
          actions={actions}
          fetchData={fetchData}
        />
      </Div>
      {openSessionView && (
        <EditSession
          openSessionView={openSessionView}
          setOpenSessionView={setOpenSessionView}
          sessionId={sessionId}
          roomId={id}
        />
      )}
    </React.Fragment>
  );
};

export default AddSession;
