import { ROOM_LIST, ROOM_ERROR, ROOM_REQUEST } from "./roomConstant";
import {
  ROOM_VIEW_LIST,
  ROOM_VIEW_ERROR,
  ROOM_VIEW_REQUEST,
} from "./roomViewConstant";
import { Axios } from "app/services/config";
import {
  SESSION_ERROR,
  SESSION_LIST,
  SESSION_REQUEST,
} from "./sessionConstant";
import {
  SESSION_VIEW_GUEST_ERROR,
  SESSION_VIEW_GUEST_LIST,
  SESSION_VIEW_GUEST_REQUEST,
} from "./sessionViewGuestConstant";

const setError = (type, error) => ({ type, payload: error });
const clearError = (type) => ({ type, payload: null });

export const onRoomList = (query) => async (dispatch) => {
  try {
    dispatch(clearError(ROOM_ERROR));
    dispatch({ type: ROOM_REQUEST });

    let apiUrl = `/room/list`;
    if (query) {
      const queryParams = new URLSearchParams(query);
      apiUrl =
        apiUrl + (queryParams.toString() ? `?${queryParams.toString()}` : "");
    }
    const { data } = await Axios.get(apiUrl);

    dispatch({ type: ROOM_LIST, payload: data });
  } catch (error) {
    // setLoading(false);
    dispatch(setError(ROOM_ERROR, error.message));
  } finally {
    // setLoading(false);
  }
};

export const onRoomViewList = (query, id) => async (dispatch) => {
  try {
    dispatch(clearError(ROOM_VIEW_ERROR));
    dispatch({ type: ROOM_VIEW_REQUEST });
    let apiUrl = `/room/get-all-session-basedOn-roomId?id=${id}`;
    if (query) {
      const queryParams = new URLSearchParams(query);
      apiUrl += apiUrl.includes("?")
        ? `&${queryParams.toString()}`
        : `?${queryParams.toString()}`;
    }
    console.log(apiUrl, "apiUrl");
    const { data } = await Axios.get(apiUrl);

    dispatch({ type: ROOM_VIEW_LIST, payload: data });
  } catch (error) {
    // setLoading(false);
    dispatch(setError(ROOM_VIEW_ERROR, error.message));
  } finally {
    // setLoading(false);
  }
};
export const onRoomViewGuestList = (query, id, sessionId) => async (dispatch) => {
  try {
    dispatch(clearError(SESSION_VIEW_GUEST_ERROR));
    dispatch({ type: SESSION_VIEW_GUEST_REQUEST });
    let apiUrl = `/room//get-all-guest-basedOn-sessionId-and-roomdId?id=${id}&session=${sessionId}`;
    if (query) {
      const queryParams = new URLSearchParams(query);
      apiUrl += apiUrl.includes("?")
        ? `&${queryParams.toString()}`
        : `?${queryParams.toString()}`;
    }
    console.log(apiUrl, "apiUrl");
    const { data } = await Axios.get(apiUrl);

    dispatch({ type: SESSION_VIEW_GUEST_LIST, payload: data });
  } catch (error) {
    // setLoading(false);
    dispatch(setError(SESSION_VIEW_GUEST_ERROR, error.message));
  } finally {
    // setLoading(false);
  }
};

export const onSessionList = (query, id) => async (dispatch) => {
  try {
    console.log(id, "apiUrl");
    dispatch(clearError(SESSION_ERROR));
    dispatch({ type: SESSION_REQUEST });
    let apiUrl = `/session/list-session?id=${id}`;
    if (query) {
      const queryParams = new URLSearchParams(query);
      apiUrl += apiUrl.includes("?")
        ? `&${queryParams.toString()}`
        : `?${queryParams.toString()}`;
    }

    const { data } = await Axios.get(apiUrl);

    dispatch({ type: SESSION_LIST, payload: data });
  } catch (error) {
    // setLoading(false);
    dispatch(setError(SESSION_ERROR, error.message));
  } finally {
    // setLoading(false);
  }
};
