import React, { useState, useEffect } from "react";
import { Grid, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Button from "@mui/material/Button";
import { Form, Formik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import * as yup from "yup";
import { Axios } from "app/services/config";
import ToastAlerts from "app/components/Toast";
import { useDispatch } from "react-redux";
import { onSessionList } from "app/redux/actions/Room";

const EditSession = ({
  openSessionView,
  setOpenSessionView,
  sessionId,
  roomId,
}) => {

  const showAlert = ToastAlerts();
  const dispatch = useDispatch();
  const [room, setRoom] = useState();


  useEffect(() => {
    const fetchData = async () => {
      console.log(sessionId, "sessionId");
      if (sessionId) {
        try {
          const response = await Axios.get(
            `/session/get-session-details?id=${sessionId}`
          );

          console.log(response, "response");
          setRoom(response.data.data);
        } catch (error) {
          console.error("Error fetching room data:", error);
        }
      }
    };
    fetchData();
  }, [sessionId]);


  var initialValues = {
    session_name: room?.session_name ? room?.session_name : "",
    session_description: room?.session_description
      ? room?.session_description
      : "",
  };

  const validationSchema = yup.object({
    session_name: yup.string("Enter Session Name").required("Session Name is required"),
    session_description: yup
      .string("Enter Session Description")
      .required("Session Description is required"),
  });


  const handleSessionSubmit = async (data, setSubmitting) => {
    try {
      setSubmitting(true);
      await Axios.patch(`/session/edit-session?id=${sessionId}`, data);
      showAlert("success", "Session updated successfully.");
      dispatch(onSessionList({}, roomId));
      setOpenSessionView(false);
    } catch (error) {
      showAlert("error", error.response.data.message);
    } finally {
      setSubmitting(false);
    }
  };


  useEffect(() => {
    dispatch(onSessionList({}, roomId));
  }, []);

  return (
    <Dialog
      open={openSessionView}
      onClose={() => setOpenSessionView(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <DialogTitle
        style={{ backgroundColor: "#7352C7", color: "white" }}
        id="alert-dialog-title"
      >
        EDIT SESSION
      </DialogTitle>
      <DialogContent>
        <Formik
          validateOnChange={true}
          key={JSON.stringify(initialValues)}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(data, { setSubmitting }) => {
            validationSchema
              .validate(data, { abortEarly: false })
              .then(() => {
                handleSessionSubmit(data, setSubmitting);
              })
              .catch((validationErrors) => {
                console.error("Validation Errors:", validationErrors);
              });
          }}
        >
          {({ setFieldValue, isSubmitting, values, errors, touched }) => (
            <Form noValidate autoComplete="off">
              <Grid container rowSpacing={3} mt={1}>
                <Grid item xs={12}>
                  <JumboTextField
                    fullWidth
                    required
                    id="session_name"
                    name="session_name"
                    label="Session Name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <JumboTextField
                  required
                    fullWidth
                    id="session_description"
                    name="session_description"
                    label="Session Description"
                    multiline
                  />
                </Grid>
              </Grid>
              <Grid
                container
                style={{ justifyContent: "flex-end" }}
                columnGap={3}
                mt={5}
              >
                <Grid item>
                  <LoadingButton
                    variant="contained"
                    size="medium"
                    type="submit"
                    loading={isSubmitting}
                  >
                    Save
                  </LoadingButton>
                </Grid>
                <Grid item textAlign="left">
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setOpenSessionView(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default EditSession;
