export const VISIBLE_DIALOG_OPTIONS = {
    NONE: "none",
    ROOM_VIEW_EDIT: "contact-edit-dialog",
    ROOM_VIEW_DETAIL: "contact-detail-dialog",
    ROOM_VIEW_CONFIRM_DELETE: "contact-confirm-delete",
    LABEL_EDIT: "label-edit-dialog",
    LABEL_CONFIRM_DELETE: "label-confirm-delete",
};
export const ROOM_VIEW_ADDED = "room_view-add";
export const ROOM_VIEW_DELETE = "room_view-delete";
export const ROOM_VIEW_EDIT = "room_view-edit";
export const ROOM_VIEW_LIST = "room_view-list";
export const GLOBAL_ROOM_VIEW_LIST = "global-room_view-list";
export const ROOM_VIEW_ERROR = "room_view-error";
export const ROOM_VIEW_REQUEST = "room_request";

export const ROOM_VIEW_DELETE_COMPLETED = "set-on-contact-delete-complete";
export const ROOM_VIEW_EDIT_COMPLETED = "set-on-contact-edit-complete";
export const ROOM_VIEW_READ_STARTED = "set-on-contact-read-start";
export const ROOM_VIEW_READ_COMPLETED = "set-on-contact-read-complete";
export const HIDE_VISIBLE_DIALOG = "set-visible-dialog";

export const LABEL_ADDED = "set-on-label-add";
export const LABEL_EDIT_STARTED = "set-on-label-edit-start";
export const LABEL_EDIT_COMPLETED = "set-on-label-edit-completed";
export const LABEL_DELETE_STARTED = "set-on-label-delete-start";
export const LABEL_DELETE_COMPLETED = "set-on-label-delete-complete";
export const LABEL_LIST_REFRESH_COMPLETED = "set-on-label-list-refresh-completed";

export const ASSIGN_LABELS_TO_ROOM_VIEW = "set-on-assign-labels-to-contacts";
